import { useState } from "react";
import ColorCharts from "./ColorCharts";
import HairSwitches from "./HairSwitches";
import HS from "../../../img/gal-16.jpg";
import CC from "../../../img/gal-10.jpg";
import "./tab-menu.css";

const TabMenu = (props) => {
  const { translation } = props;
  const [currentTab, setCurrentTab] = useState(0);

  return (
    <div data-aos={props.aosAnimation} className="tabmenu-wrapper">
      <article className="tabmenu-content">
        <nav className="tabmenu-buttons">
          <button
            className={currentTab === 0 ? "active-tab-button" : "tab-button"}
            onClick={() => setCurrentTab(0)}
          >
            COLOR CHARTS
          </button>
          <button
            className={currentTab === 1 ? "active-tab-button" : "tab-button"}
            onClick={() => setCurrentTab(1)}
          >
            HAIR SWITCHES
          </button>
          <br />
          <br />
          <br />
        </nav>
        <div className="tab-content-wrapper">
          {currentTab === 0 ? (
            <ColorCharts translation={translation} />
          ) : (
            <HairSwitches translation={translation} />
          )}
        </div>
      </article>
      <div className="tabmenu-image-wrapper">
        <img
          className="tabmenu-image"
          src={currentTab === 0 ? CC : HS}
          alt={currentTab === 0 ? "Color charts" : "Hair switches"}
        />
      </div>
    </div>
  );
};

export default TabMenu;
