import "./main-button.css";

const MainButton = (props) => {
  return (
    <button className="main-button" onClick={props.onClick}>
      {props.legend.toUpperCase()}
    </button>
  );
};

export default MainButton;
