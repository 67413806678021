import MainHeading from "../../common/headings/main-heading/MainHeading";
import PrimaryText from "../../common/text/primary-text/PrimaryText";
import Bullet from "../../common/text/bullet/Bullet";

const ColorCharts = (props) => {
  const { translation } = props;
  const {
    heading,
    firstParagraph,
    secondParagraph,
    firstBullet,
    secondBullet,
    thirdBullet,
    fourthBullet,
    fifthBullet,
    sixthBullet,
    seventhBullet,
  } = translation.colorCharts;

  return (
    <div>
      <MainHeading legend={heading} />
      <br />
      <PrimaryText text={firstParagraph} />
      <br />
      <PrimaryText text={secondParagraph} />
      <br />
      <Bullet text={firstBullet} />
      <br />
      <Bullet text={secondBullet} />
      <br />
      <Bullet text={thirdBullet} />
      <br />
      <Bullet text={fourthBullet} />
      <br />
      <Bullet text={fifthBullet} />
      <br />
      <Bullet text={sixthBullet} />
      <br />
      <Bullet text={seventhBullet} />
    </div>
  );
};

export default ColorCharts;
