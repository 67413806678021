import Home from "../home/Home";
import About from "../about/About";
import Production from "../production/Production";
import Products from "../products/Products";
import Tabmenu from "../tab-menu/Tabmenu";
import Contact from "../contact/Contact";
import "./module-wrapper.css";

const ModuleWrapper = (props) => {
  const { translation } = props;
  return (
    <div className="module-wrapper">
      <Home
        aosAnimation={props.homeAnimation}
        aboutHref={props.aboutHref}
        contactHref={props.contactHref}
        id={props.homeId}
        translation={translation}
      />
      <About
        aosAnimation={props.aboutAnimation}
        id={props.aboutId}
        translation={translation}
      />
      <Production
        aosAnimation={props.productionAnimation}
        id={props.productionId}
        translation={translation}
      />
      <Products
        aosAnimation={props.productsAnimation}
        id={props.productsId}
        translation={translation}
      />
      <Tabmenu
        aosAnimation={props.tabmenuAnimation}
        translation={translation}
      />
      <Contact
        aosAnimation={props.contactAnimation}
        id={props.contactId}
        translation={translation}
      />
    </div>
  );
};

export default ModuleWrapper;
