import gal1 from "../../../img/gal-1.jpg";
import gal2 from "../../../img/gal-2.jpg";
import gal3 from "../../../img/gal-3.jpg";
import gal4 from "../../../img/gal-4.jpg";
import gal5 from "../../../img/gal-5.jpg";
import gal6 from "../../../img/gal-6.jpg";
import gal7 from "../../../img/gal-7.jpg";
import gal8 from "../../../img/gal-8.jpg";
import gal9 from "../../../img/gal-9.jpg";
import gal10 from "../../../img/gal-10.jpg";
import gal11 from "../../../img/gal-11.jpg";
import gal12 from "../../../img/gal-12.jpg";
import gal13 from "../../../img/gal-13.jpg";
import gal14 from "../../../img/gal-14.jpg";
import gal15 from "../../../img/gal-15.jpg";
import gal16 from "../../../img/gal-16.jpg";

const images = [
  gal1,
  gal2,
  gal3,
  gal4,
  gal5,
  gal6,
  gal7,
  gal8,
  gal9,
  gal10,
  gal11,
  gal12,
  gal13,
  gal14,
  gal15,
  gal16,
];

export default images;
