import SolidDivider from "../../common/dividers/solid-divider/SolidDivider";
import MainButton from "../../common/buttons/main-button/MainButton";
import "./home.css";

const Home = (props) => {
  const { translation } = props;
  const { heading, text, buttons } = translation.home;

  return (
    <div data-aos={props.aosAnimation} id={props.id} className="home-wrapper">
      <div className="mobile-home-background"></div>
      <article className="home-content">
        <h1 className="main-heading">{heading}</h1>
        <br />
        <SolidDivider />
        <br />
        <p className="home-text">{text}</p>
        <br />
        <br />
        <nav className="home-nav">
          <a href={props.aboutHref}>
            <MainButton legend={buttons.more} />
          </a>
          <a href={props.contactHref}>
            <MainButton legend={buttons.contact} />
          </a>
        </nav>
      </article>
    </div>
  );
};

export default Home;
