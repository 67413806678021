import { useState, useRef } from "react";
import emailjs from "@emailjs/browser";
import MainHeading from "../../common/headings/main-heading/MainHeading";
import SolidDivider from "../../common/dividers/solid-divider/SolidDivider";
import MainButton from "../../common/buttons/main-button/MainButton";
import PrimaryText from "../../common/text/primary-text/PrimaryText";
import "./contact.css";

const Contact = (props) => {
  const { translation } = props;
  const { formfields } = translation.contact;
  const form = useRef();
  const [contactForm, setContactForm] = useState({
    name: "",
    phone: "",
    email: "",
    company: "",
    message: "",
  });

  const handleSubmit = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "cartacor_service",
        "template_g8ekouo",
        form.current,
        "Sr4_C1FzeYb8zapc7"
      )
      .then(
        ((result) => {
          alert("Mensaje enviado");

          console.log(result.text);

          alert("Mensaje enviado");

          setContactForm({
            name: "",
            phone: "",
            email: "",
            company: "",
            message: "",
          });
        },
        (error) => {
          console.log(error.text);

          alert("Mensaje enviado");

          setContactForm({
            name: "",
            phone: "",
            email: "",
            company: "",
            message: "",
          });
        })
      );
  };

  return (
    <div
      data-aos={props.aosAnimation}
      id={props.id}
      className="contact-wrapper"
    >
      <form ref={form} onSubmit={handleSubmit} className="contact-form">
        <MainHeading legend={translation.contact.heading} />
        <br />
        <SolidDivider />
        <br />
        <br />
        <div className="formfields">
          <input
            className="formfield"
            placeholder={formfields.name}
            id="name"
            name="name"
            value={contactForm.name}
            onChange={(e) =>
              setContactForm({ ...contactForm, name: e.target.value })
            }
            type="text"
          />
          <input
            className="formfield"
            placeholder={formfields.phone}
            id="phone"
            name="phone"
            value={contactForm.phone}
            onChange={(e) =>
              setContactForm({ ...contactForm, phone: e.target.value })
            }
            type="phone"
          />
          <input
            className="formfield"
            placeholder={formfields.email}
            id="email"
            name="email"
            value={contactForm.email}
            onChange={(e) =>
              setContactForm({ ...contactForm, email: e.target.value })
            }
            type="email"
          />
          <input
            className="formfield"
            placeholder={formfields.company}
            id="company"
            name="company"
            value={contactForm.company}
            onChange={(e) =>
              setContactForm({ ...contactForm, company: e.target.value })
            }
            type="text"
          />
          <textarea
            className="formfield formfield-message"
            placeholder={formfields.message}
            id="message"
            name="message"
            value={contactForm.message}
            onChange={(e) =>
              setContactForm({ ...contactForm, message: e.target.value })
            }
            cols="30"
            rows="10"
          ></textarea>
          <MainButton legend={formfields.button} />
        </div>
      </form>
      <div className="adress-data">
        <iframe
          title="Cartacor map"
          className="contact-map"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3761.0049486122452!2d-99.15837725892959!3d19.49842290027339!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x85d1f84669da86bb%3A0x1194bfe5a46e85da!2sNorte%2045%201020%2C%20Industrial%20Vallejo%2C%20Azcapotzalco%2C%2002300%20Ciudad%20de%20M%C3%A9xico%2C%20CDMX!5e0!3m2!1ses-419!2smx!4v1644473858958!5m2!1ses-419!2smx"
          loading="lazy"
        ></iframe>
        <PrimaryText text="Norte 45 N° 1020" />
        <PrimaryText text="Col. Industial Vallejo" />
        <PrimaryText text="Delegación Azcapotzalco" />
        <PrimaryText text="Ciudad de México | CP 02300" />
        <PrimaryText text="Tel: +52 (55) 5368 4542" />
        <PrimaryText text="Cel: +52 (55) 4611 0706" />
      </div>
      {/*<img src={contactImage} alt="sunglasses girl" className="contact-image" />*/}
    </div>
  );
};

export default Contact;
