import { useState, useEffect } from "react";
import Aos from "aos";
import translation from "./translation";
import Header from "./components/layout/header/Header";
import ModuleWrapper from "./components/modules/module-wrapper/ModuleWrapper";
import Footer from "./components/layout/footer/Footer";
import whatsapp from "./img/whatsapp.png";
import "aos/dist/aos.css";
import "./App.css";

const App = () => {
  useEffect(() => {
    Aos.init({ duration: 2500 });
  }, []);

  const { spanish, english } = translation;
  const [currentLanguage, setCurrentLanguage] = useState(spanish);

  return (
    <div className="App">
      <Header
        translation={currentLanguage}
        onSpanishClick={() => setCurrentLanguage(spanish)}
        onEnglishClick={() => setCurrentLanguage(english)}
        homeHref="#home"
        aboutHref="#about"
        productionHref="#production"
        productsHref="#products"
        contactHref="#contact"
      />
      <a
        href="https://wa.me/525546104331"
        target="_blank"
        rel="noreferrer"
        className="whatsapp-button"
      >
        <img src={whatsapp} alt="whatsapp" className="whatsapp-logo" />
        <p className="whatsapp-text">(55) 4610 4331</p>
      </a>
      <ModuleWrapper
        translation={currentLanguage}
        homeId="home"
        aboutId="about"
        productionId="production"
        productsId="products"
        contactId="contact"
        homeAnimation="fade-down"
        aboutAnimation="fade-right"
        productionAnimation="fade-right"
        productsAnimation="fade-right"
        tabmenuAnimation="fade-right"
        contactAnimation="fade-up"
        aboutHref="#about"
        contactHref="#contact"
      />
      <Footer />
    </div>
  );
};

export default App;
