import MainHeading from "../../common/headings/main-heading/MainHeading";
import PrimaryText from "../../common/text/primary-text/PrimaryText";

const HairSwitches = (props) => {
  const { translation } = props;
  const {
    heading,
    firstParagraph,
    secondParagraph,
    thirdParagraph,
    fourthParagraph,
  } = translation.hairSwitches;
  return (
    <div>
      <MainHeading legend={heading} />
      <br />
      <PrimaryText text={firstParagraph} />
      <br />
      <PrimaryText text={secondParagraph} />
      <br />
      <PrimaryText text={thirdParagraph} />
      <br />
      <PrimaryText text={fourthParagraph} />
    </div>
  );
};

export default HairSwitches;
