import MainHeading from "../../common/headings/main-heading/MainHeading";
import GradientDivider from "../../common/dividers/gradient-divider/GradientDivider";
import PrimaryText from "../../common/text/primary-text/PrimaryText";
import "./about.css";

const About = (props) => {
  const { translation } = props;
  const { heading, firstParagraph, secondParagraph } = translation.about;

  return (
    <div data-aos={props.aosAnimation} id={props.id} className="about-wrapper">
      <article className="about-content">
        <GradientDivider />
        <br />
        <MainHeading legend={heading} />
        <br />
        <GradientDivider />
        <br />
        <br />
        <PrimaryText text={firstParagraph} />
        <br />
        <PrimaryText text={secondParagraph} />
      </article>
    </div>
  );
};

export default About;
