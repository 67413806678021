const spanish = {
  code: 0,
  header: {
    buttons: {
      home: "inicio",
      about: "nosotros",
      production: "producción",
      products: "productos",
      contact: "contacto",
    },
  },
  home: {
    heading: "Producción de Cartas de Color de Cabello para Estilistas",
    text: "Cartacor México se especializa en el diseño y producción de catálogos de colores para el mercado tricológico y cosmético. Con experiencia consolidada en el mercado internacional, Cartacor México también es parte de grupo Cartacor, después de la apertura de Cartacor Brasil y Cartacor Italia.",
    buttons: {
      more: "ver más",
      contact: "contacto",
    },
  },
  about: {
    heading: "Nosotros",
    firstParagraph:
      "Cartacor México se especializa en el diseño y producción de catálogos de colores para el mercado tricológico y cosmético. Con experiencia consolidada en el mercado internacional, Cartacor México también es parte de grupo Cartacor, después de la apertura de Cartacor Brasil y Cartacor Italia. En Cartacor seguimos por completo el proceso de producción, desde la producción de esquemas sitnéticos hasta la creación de contratipos, el desarrollo de gráficos e impresión, hasta la entrega del producto finalizado.",
    secondParagraph:
      "La experiencia y profesionalismo de nuestro equipo, combinado con la eficiencia de nuestra sede de producción, garantiza un producto de alta calidad, hecho a la medida para cada uno de nuestros clientes. Nos ponemos a disposición de nuestros clientes interpretando su necesidad de producir productos originales, innovadores y de alta calidad.",
  },
  production: {
    heading: "Producción",
    firstParagraph:
      "La alta calidad de los productos ofrecidos por nuestra compañía y la alta especialización se han vuelto un punto de referencia para importantes compañías nacionales e internacionales en el sector cosmético y tricológico.",
    secondParagraph:
      "En un mercado altamente competitivo, es esencial mantenerse actualizado ofreciendo productos innovadores funcionales. Nuestros productos son hechos a la medida para cada uno de nuestros clientes. La investigación en materiales y diseño refleja la filosofía de las compañías que confían en nosotros, y así, respondemos a sus necesidades.",
    thirdParagraph:
      "Hemos desarrollado una línea de producción dedicada a compañías y profesionales en el sector que necesiten distribuir un número limitado de tarjetas de color usando impresión digital, lo que garantiza resultados de alta calidad sin estar restringidos a altas cantidades.",
    fourthParagraph:
      "Diseñamos y desarrollamos muestras y prototipos, trabajamos paso a paso juntos con nuestros clientes proporcionando un servicio de consultoría real. La experiencia obtenida en el campo de impresión y gráficos nos permite estar particularmente atentos a las necesidades de nuestros clientes, quienes necesitan cada vez más socios profesionales con habilidades específicas.",
  },
  products: {
    heading: "Productos",
    firstParagraph:
      "La carta de colores es una herramienta fundamental para consultoría en el salón. Los productos colorantes ofrecidos en el mercado hoy en día son muy variados, por lo tanto, es importante que la carta de colores también refleje que producto presentado al máximo.",
    secondParagraph:
      "Para esto, podemos crear una gran variedad de cartas de colores, desde las más simples y económicas hasta las más buscadas, tanto en materiales como en formas. Gracias a nuestra experiencia podemos ofrecer un servicio de rápido y de calidad, desde el papel y diseño gráfico hasta la realización del producto finalizado.",
  },
  colorCharts: {
    heading: "Cartas de Colores",
    firstParagraph:
      "La carta de colores es una herramienta fundamental para consultoría en el salón. Los productos colorantes ofrecidos en el mercado hoy en día son muy variados, por lo tanto, es importante que la carta de colores también refleje que producto presentado al máximo.",
    secondParagraph:
      "Producimos una gran variedad de cartas de colores, desde la más tradicional hasta la más prestigiosa y buscada tanto en los mecanismos como en los materiales a utilizar. En particular producimos:",
    firstBullet:
      "Cartas de color con puertas: son las cartas de color más tradicionales y constituyen el núcleo de nuestro negocio.",
    secondBullet:
      "Folders: para hojear como un libro y dejar que el cliente descubra toda la gama de colores página tras página. Se pueden hacer con o sin índice.",
    thirdBullet:
      "Carpetas de anillas: ideales para mostrar toda la línea de un vistazo y para actualizar fácilmente la carpeta con extensiones de rango.",
    fourthBullet:
      "Pantones: al igual que los pantones clásicos para colores impresos, los diversos matices de color también se pueden mostrar en un pantonario.",
    fifthBullet:
      "Expositores de pancarta y mostrador: Ideales para llamar la atención del cliente sobre los nuevos colores y tendencias de la temporada.",
    sixthBullet:
      "Carpetas “interactivas”: se pueden realizar en varios tamaños y formas, con las cerraduras sobre soportes removibles o fijos, pero el denominador común es llamar la atención del cliente final y facilitar la consulta en el salón.",
    seventhBullet: "Megaboard: impactante y muy escenográfico",
  },
  hairSwitches: {
    heading: "Mechones de cabello",
    firstParagraph:
      "Los tipos de cerraduras que podemos fabricar son de lo más variados, dando así la posibilidad de personalizar al máximo su carta de colores eligiendo la forma de cerradura que mejor se adapte al producto a presentar.",
    secondParagraph:
      "Además de la forma y tamaño de las hebras, es posible elegir entre una serie de soportes desmontables sobre los que se montan, que hacen que las cartas de colores sean especiales y versátiles. El hilo se compra en bruto y posteriormente se somete a procesos de tratamiento y tintura, esto nos permite ofrecer una amplia gama de tonos.",
    thirdParagraph:
      "En diferentes momentos del proceso de producción, se realiza un control de la calidad del color del hilo, asegurando así la máxima correspondencia entre los hilos insertados en la carpeta y las muestras aprobadas por el cliente.",
    fourthParagraph:
      "Trabajamos con gran precisión en la fase de contra mecanografía para garantizar a nuestros clientes un resultado excelente.",
  },
  contact: {
    heading: "Contacto",
    formfields: {
      name: "Nombre",
      phone: "Teléfono",
      email: "Correo Electrónico",
      company: "Compañía",
      message: "Mensaje",
      button: "Enviar",
    },
  },
};

const english = {
  code: 1,
  header: {
    buttons: {
      home: "home",
      about: "about",
      production: "production",
      products: "products",
      contact: "contact",
    },
  },
  home: {
    heading: "Production of Hair Color Charts for Hairdressers",
    text: "Cartacor Mexico specializes in the design and production of color charts for the trichological and cosmetic market. With consolidated experience on the international market, Cartacor Mexico also becomes part of the Cartacor group, after the opening of Cartacor Brazil and Cartacor Italia.",
    buttons: {
      more: "see more",
      contact: "contact",
    },
  },
  about: {
    heading: "About Us",
    firstParagraph:
      "Cartacor Mexico specializes in the design and production of color charts for the trichological and cosmetic market. With consolidated experience on the international market, Cartacor Mexico also becomes part of the Cartacor group, after the opening of Cartacor Brazil and Cartacor Italia. In Cartacor we follow the whole production process; from the production of synthetic locks to the creation of countertypes, the development of graphics and printing up to the delivery of the finished product.",
    secondParagraph:
      "The experience and professionalism of our team, combined with the efficiency of our production site, guarantee a high-quality product, tailor-made for each of our customers. We make ourselves available to the customers by interpreting their needs to produce original, innovative and quality products.",
  },
  production: {
    heading: "Production",
    firstParagraph:
      "The high quality of the products offered by our company and the high specialization have made it a point of reference for important national and international companies in the cosmetic and trichological sector.",
    secondParagraph:
      "In a highly competitive market it is essential to keep up with the times by offering innovative and at the same time functional products. Our products are tailor-made for each of our customers. The research of materials and design reflects the philosophy of the companies that rely on us and responds to their needs.",
    thirdParagraph:
      "We have developed a production line dedicated to companies and professionals in the sector who need to distribute a limited number of color cards using digital printing, which guarantees very high quality results, without the constraint of large quantities.",
    fourthParagraph:
      "We design and develop samples and prototypes, we work step by step together with our customers providing a real consulting service. The experience gained also in the field of printing and graphics allows us to be particularly attentive to the needs of our customers, who increasingly need professional partners with specific skills.",
  },
  products: {
    heading: "Products",
    firstParagraph:
      "The color chart is a fundamental tool for consultation and consultancy within the salon. The coloring products that are offered on the market today are very varied, it is therefore important that the color chart also reflects the product presented to the maximum.",
    secondParagraph:
      "To this end, we can create a wide range of color cards, from the simplest and cheapest ones to the most sought-after color cards, both in materials and shapes. Thanks to our experience we can offer a fast and qualitative service, which starts from the paper and graphic design to the realization of the finished product.",
  },
  colorCharts: {
    heading: "Color Charts",
    firstParagraph:
      "The color chart is a fundamental tool for consultation and consultancy within the salon. The coloring products that are offered on the market today are very varied, it is therefore important that the color chart also reflects the product presented to the maximum.",
    secondParagraph:
      "We produce a wide range of color charts, from the most traditional to the most prestigious and sought after both in the mechanisms and in the materials to be used. In particular we produce:",
    firstBullet:
      "Color charts with doors: these are the most traditional color charts and constitute our core business.",
    secondBullet:
      "Folders: to be leafed through like a book and let the customer discover the full range of colors page after page. They can be made with or without indexing.",
    thirdBullet:
      "Ring binders: ideal for showing the whole line at a glance and for easily updating the folder with range extensions.",
    fourthBullet:
      "Pantones: just like the classic pantones for print colors, the various coloring nuances can also be displayed in a pantonarium.",
    fifthBullet:
      "Pancarte and counter displays: Ideal for drawing the customer's attention to the new colors and trends of the season.",
    sixthBullet:
      "'Interactive' folders: they can be made in various sizes and shapes, with the locks on removable or fixed supports, but the common denominator is to attract the attention of the end customer and facilitate consultation in the salon",
    seventhBullet: "Megaboard: impactful and very scenographic.",
  },
  hairSwitches: {
    heading: "Hair Switches",
    firstParagraph:
      "The types of locks that we can produce are the most varied, thus giving the possibility to customize your color chart to the maximum by choosing the shape of the lock that best suits the product to be presented.",
    secondParagraph:
      "In addition to the shape and size of the strands, it is possible to choose from a series of removable supports on which they are mounted, which make the color charts special and versatile.The yarn is purchased raw and subsequently subjected to treatment and dyeing processes, this allows us to offer a wide range of shades.",
    thirdParagraph:
      "At different moments of the production process, a check on the quality of the yarn color is carried out, thus ensuring the maximum correspondence between the strands inserted in the folder and the samples approved by the customer.",
    fourthParagraph:
      "We work with great precision in the counter-typing phase to guarantee our customers an excellent result.",
  },
  contact: {
    heading: "Contact",
    formfields: {
      name: "Name",
      phone: "Phone",
      email: "Email",
      company: "Company",
      message: "Message",
      button: "Send",
    },
  },
};

const translation = {
  spanish,
  english,
};

export default translation;
