import MainHeading from "../../common/headings/main-heading/MainHeading";
import GradientDivider from "../../common/dividers/gradient-divider/GradientDivider";
import PrimaryText from "../../common/text/primary-text/PrimaryText";
import production1 from "../../../img/production-1.png";
import production2 from "../../../img/production-2.png";
import production3 from "../../../img/production-3.png";
import "./production.css";

const Production = (props) => {
  const { translation } = props;
  const {
    heading,
    firstParagraph,
    secondParagraph,
    thirdParagraph,
    fourthParagraph,
  } = translation.production;

  return (
    <div
      data-aos={props.aosAnimation}
      id={props.id}
      className="production-wrapper"
    >
      <article className="production-content">
        <GradientDivider />
        <br />
        <MainHeading legend={heading} />
        <br />
        <GradientDivider />
        <br />
        <PrimaryText text={firstParagraph} />
        <br />
        <PrimaryText text={secondParagraph} />
        <br />
        <PrimaryText text={thirdParagraph} />
        <br />
        <PrimaryText text={fourthParagraph} />
        <br />
        <br />
        <div className="production-images">
          <img
            className="production-image"
            src={production1}
            alt="Production"
          />
          <img
            className="production-image"
            src={production2}
            alt="Production"
          />
          <img
            className="production-image"
            src={production3}
            alt="Production"
          />
        </div>
      </article>
    </div>
  );
};

export default Production;
