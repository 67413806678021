import PrimaryText from "../../common/text/primary-text/PrimaryText";
import "./footer.css";

const Footer = () => {
  return (
    <footer className="site-footer">
      <PrimaryText text="Copyright 2021 Cartacor México" />
    </footer>
  );
};

export default Footer;
