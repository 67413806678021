import "./header-button.css";

const HeaderButton = (props) => {
  return (
    <a className="header-button" href={props.href}>
      {props.legend.toUpperCase()}
    </a>
  );
};

export default HeaderButton;
