import HeaderButton from "../../common/buttons/header-button/HeaderButton";
import logo from "../../../img/logo.png";
import "./header.css";

const Header = (props) => {
  const { translation } = props;
  const { buttons } = translation.header;

  return (
    <header className="site-header">
      <div className="header-left-content">
        <img className="header-logo" src={logo} alt="Cartacor" />
        <nav className="header-navbar">
          <HeaderButton href={props.homeHref} legend={buttons.home} />
          <HeaderButton href={props.aboutHref} legend={buttons.about} />
          <HeaderButton
            href={props.productionHref}
            legend={buttons.production}
          />
          <HeaderButton href={props.productsHref} legend={buttons.products} />
          <HeaderButton href={props.contactHref} legend={buttons.contact} />
        </nav>
      </div>
      <nav className="language-toggle">
        <button
          onClick={props.onSpanishClick}
          className={
            translation.code === 0
              ? "language-button-active"
              : "language-button"
          }
        >
          Español
        </button>
        <button
          onClick={props.onEnglishClick}
          className={
            translation.code === 1
              ? "language-button-active"
              : "language-button"
          }
        >
          English
        </button>
      </nav>
    </header>
  );
};

export default Header;
