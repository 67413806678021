import { useState } from "react";
import MainHeading from "../../common/headings/main-heading/MainHeading";
import SolidDivider from "../../common/dividers/solid-divider/SolidDivider";
import PrimaryText from "../../common/text/primary-text/PrimaryText";
import images from "./images";
import "./products.css";

const Products = (props) => {
  const { translation } = props;
  const { heading, firstParagraph, secondParagraph } = translation.products;
  const [selectedImage, setSelectedImage] = useState(images[0]);

  return (
    <div
      data-aos={props.aosAnimation}
      id={props.id}
      className="products-wrapper"
    >
      <article className="products-content">
        <MainHeading legend={heading} />
        <br />
        <SolidDivider />
        <br />
        <br />
        <PrimaryText text={firstParagraph} />
        <br />
        <PrimaryText text={secondParagraph} />
      </article>
      <section className="products-gallery">
        <img src={selectedImage} alt="Our work" className="gallery-selected" />
        <div className="gallery-images">
          {images.map((image, index) => (
            <img
              key={index}
              src={image}
              alt="Our work"
              className="gallery-small"
              onClick={() => setSelectedImage(images[index])}
            />
          ))}
        </div>
      </section>
    </div>
  );
};

export default Products;
